/* Container styling for compact experience */
.custom-slider-container {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto; /* Center horizontally */
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

/* Fixed height and width for compact slider */
.slider-wrapper {
  width: 100%; /* Full width to adapt to different screen sizes */
  height: 600px; /* Fixed height for the slider */

  position: relative;
  overflow: hidden; /* Ensure content stays within bounds */
  border-radius: 1rem;
}

/* Slider wrapper with smooth transitions */
.custom-slider-wrapper {
  display: flex;
  height: 100%;
  transition: transform 0.8s ease-in-out;
}

/* For the individual slides */
.custom-slider {
  min-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-size: cover; /* Ensure image covers the entire slider */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent image repetition */
  background-color: #000; /* Background color in case of gaps */
}

/* Background video styling */
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

/* Overlay with text */
.slide-content {
  position: absolute;
  bottom: 20px;
  max-width: 80%;
  padding: 15px;
  background: rgba(0, 0, 0, 0.6); /* Slightly transparent background */
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.8);

  &.left-4 {
    left: 20px; /* Left positioning for English */
  }

  &.right-4 {
    right: 20px; /* Right positioning for Kurdish */
  }
}

/* Text styling */
.text-overlay h2 {
  font-size: 1.5rem;
  margin: 0;
  font-weight: bold;
  line-height: 1.2;
}

.text-overlay p {
  font-size: 0.875rem;
  margin: 10px 0 0;
}

/* Pagination dots */
.pagination-dots {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #ff5733; /* Highlighted color */
}

/* Button styling */
.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.8);
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  z-index: 10;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
    transform: scale(1.1); /* Slight scale effect on hover */
  }
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.icon {
  color: white;
  width: 24px;
  height: 24px;
}

/* Active slide styling */
.active-slide {
  transform: scale(1.05) rotateY(0deg); /* Slight scale and 3D effect */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.9);
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  /* Adjust slider height for tablets */
  .slider-wrapper {
    height: 400px; /* Adjust height */
  }

  .text-overlay h2 {
    font-size: 1.25rem; /* Smaller font size for titles */
  }

  .text-overlay p {
    font-size: 0.75rem; /* Smaller font size for paragraphs */
  }

  .slider-button {
    width: 40px;
    height: 40px;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .dot {
    width: 8px;
    height: 8px;
  }
}

@media (max-width: 480px) {
  /* Adjust slider height for mobile */
  .slider-wrapper {
    height: 300px; /* Further adjusted height */
  }

  .text-overlay h2 {
    font-size: 1rem; /* Further smaller font size for titles */
  }

  .text-overlay p {
    font-size: 0.625rem; /* Further smaller font size for paragraphs */
  }

  .slider-button {
    width: 35px;
    height: 35px;
  }

  .icon {
    width: 18px;
    height: 18px;
  }

  .dot {
    width: 6px;
    height: 6px;
  }
}

.app__skills {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center contents horizontally */
  margin-top: 4rem;
}

.app__skills-container {
  width: 70%;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center; /* Center contents horizontally */
  align-items: center; /* Center contents vertically */

  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
}

.app__skills-list {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  margin-right: 5rem;

  @media screen and (max-width: 900px) {
    margin-right: 0;
  }
}

.app__skills-item {
  flex-direction: column;
  text-align: center;
  margin: 1rem;
  transition: all ease-in-out 0.3s;

  div {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #fff;
    transition: all ease-in-out 0.4s;

    img {
      width: 50%;
    }

    &:hover {
      box-shadow: 0 0 25px #16151514;
      transform: scale(1.1); /* Fixed transform property */
    }

    @media screen and (min-width: 2000px) {
      width: 150px;
      height: 150px;
    }

    @media screen and (max-width: 2000px) {
      width: 80px;
      height: 80px;
    }
  }

  p {
    font-weight: 500;
    margin-top: 0.9rem;
  }

  @media screen and (min-width: 2000px) {
    margin-top: 1rem 2rem;
  }
}

.head {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 0.5rem;
}

#dark .skills_head {
  color: var(--white-color);
}

.app__navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6rem;
  padding: 1rem 2rem;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  position: fixed;
  z-index: 30;

  &-logo img {
    width: 8rem;
    @media screen and (min-width: 2000px) {
      width: 4rem;
    }
  }

  &-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;

    li {
      margin: 0 1rem;
      cursor: pointer;

      div {
        width: 5px;
        height: 5px;
        background: transparent;
        border-radius: 50%;
        margin-bottom: 5px;
      }

      a {
        color: #333;
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: 600;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: var(--secondary-color);
        }
      }

      &:hover div {
        background: var(--secondary-color);
      }
    }

    @media screen and (max-width: 1050px) {
      display: none;
    }
  }

  &-controls {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);
    cursor: pointer;

    svg {
      width: 70%;
      height: 70%;
      color: var(--white-color);
    }

    &-content {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      width: 80%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem;
      background-color: var(--white-color);
      background-size: cover;
      box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);

      svg {
        width: 35px;
        height: 35px;
        color: var(--secondary-color);
        margin: 0.5rem 1rem;
      }

      ul {
        list-style: none;
        padding: 0;
        width: 100%;

        li {
          margin: 2rem;

          a {
            color: var(--gray-color);
            text-decoration: none;
            font-size: 1rem;
            font-weight: 500;
            transition: all 0.3s ease-in-out;

            &:hover {
              color: var(--secondary-color);
            }
          }
        }
      }

      @media screen and (min-width: 1050px) {
        display: none;
      }
    }
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

#dark .app__navbar {
  background-color: rgba(0, 0, 0, 0.342);
  border: none;
}

#dark .app__navbar a {
  color: white;
}

#dark .app__navbar-menu-content {
  background-color: #333;
  box-shadow: none;
}

.language-toggle-wrapper {
  display: flex;
  justify-content: left;
  align-items: center;
}

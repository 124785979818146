.kurdish {
  font-family: "Etestedad", sans-serif;
}

.kurdish3 {
  font-family: "Etestedad3", sans-serif;
}

.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  overflow-y: hidden;
}

.head-text {
  margin-bottom: 2rem;
  font-size: 2rem;
}

.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app__aboutme {
  width: 100%;
  max-width: 1000px;
  margin-bottom: 2rem;
}

.app__aboutme-img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.about-description {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.app__profiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.app__profile-item {
  width: 250px;
  margin: 1rem;
  text-align: center;
}

.app__profile-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.bold-text {
  margin-top: 20px;
  font-weight: bold;
}

.des {
  color: #555;
  margin-top: 10px;
}

.p-text {
  font-size: 1rem;
  line-height: 1.5;
}

.app__aboutme {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.aboutme-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 5rem;
  margin: auto;
  align-items: center;

  .app__aboutme-img {
    width: 300px; /* Adjust size as needed */
    height: auto;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin: 0rem;
    gap: 0rem;
    align-items: center;
    justify-content: center;
    p {
      overflow-wrap: break-word;
    }
  }
}

.app__profiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#dark .about-description {
  color: var(--white-color);
}

#dark .about-container .head-text {
  color: var(--white-color);
}

.about-container .head-text span {
  text-transform: none;
}

#dark .app__profiles h2 {
  color: var(--white-color);
}

.app__profiles p {
  color: var(--dark-color);
  font-weight: 600;
}

#dark .des {
  color: var(--white-color);
  font-size: 0.8rem;
  font-weight: 400;
}

.app__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  z-index: 0;
  margin-top: 4rem;
  gap: 6rem;
  margin-bottom: 4rem;
  width: 70%;

  .app__header-info {
    margin-bottom: 40px;

    .app__header-badge {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;

      .badge-cmp {
        display: flex;
        align-items: center;

        h1 {
          color: #333;
          font-size: 40px;
          font-weight: 800;
          line-height: 1.2;
        }
      }

      .tag-cmp {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;

        .p-text-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1rem;

          .p-text {
            color: #666;
            font-size: 20px;
            background-color: var(--secondary-color);
            padding: 1rem;
            border-radius: 15px;
            color: var(--white-color);
            margin: 0.5rem; // Adjust spacing as needed

            @media screen and (max-width: 790px) {
              padding: 0.4rem;
              border-radius: 10px;
            }
          }
        }

        .resume-button {
          margin-top: 20px;

          .download-btn {
            display: inline-block;
            background-color: var(--secondary-color);
            color: var(--white-color);
            padding: 10px 20px;
            border-radius: 10px;
            text-decoration: none;
            font-size: 16px;
            text-align: center;
            transition: background-color 0.3s ease;
            width: 100%; // Ensure button takes up full width of text above
            max-width: calc(
              2 * (1rem + 1rem) + 20px
            ); // Adjust based on total width of text above

            &:hover {
              background-color: var(--primary-color);
            }
          }
        }
      }
    }
  }

  .app__header-img {
    margin-bottom: 40px;

    .overlay_circle {
      width: 20rem;
      height: 20rem;
      object-fit: cover;
      border-radius: 50%;
      border: 4px solid #fff;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.5s ease-in-out;

      @media screen and (max-width: 768px) {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;

    .app__header-info {
      margin-bottom: 0;
      width: 50%;
    }

    .app__header-img {
      margin-bottom: 0;
    }

    .app__header-badge {
      align-items: flex-start;
      text-align: left;

      .badge-cmp {
        h1 {
          margin-left: 0;
        }
      }
    }
  }
}

// Animation for h1
@keyframes fadeInText {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.app__header .app__header-info .app__header-badge .badge-cmp h1 {
  animation: fadeInText 1s ease forwards;
}

#dark .greeting {
  color: var(--white-color);
}

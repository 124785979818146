@import url("https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400;500;600;700;800;900&family=Lexend:wght@100..900&family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Work+Sans:wght@300;500&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Estedad2";
  src: url("../../frontend-react/fonts/ttf/Estedad-Bold.ttf");
}

@font-face {
  font-family: "Estedad";
  src: url("../../frontend-react/fonts/ttf/Estedad-Black.ttf");
}

@font-face {
  font-family: "Estedad3";
  src: url("../../frontend-react/fonts/ttf/Estedad-Medium.ttf");
}

@font-face {
  font-family: "Estedad4";
  src: url("../../frontend-react/fonts/ttf/Estedad-Regular.ttf");
}
@font-face {
  font-family: "Estedad5";
  src: url("../../frontend-react/fonts/ttf/Estedad-Regular.ttf");
}
@font-face {
  font-family: "Estedad6";
  src: url("../../frontend-react/fonts/ttf/Estedad-ExtraLight.ttf");
}
body {
  font-family: "Raleway", sans-serif;
}

body.kurdish-font {
  font-family: "Estedad4", sans-serif;
}

:root {
  --font-base: "Raleway", sans-serif;

  --primary-color: #e8e8e8;
  --secondary-color: #138131;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #bbbfca;
  --dark-color: #515151;
  --white-color: #f4f4f2;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  scroll-behavior: smooth;
}
#dark {
  background-color: #26282a;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: var(--white-color);
  }
}

.ex__experiences {
  padding: 1rem;
  text-align: center;
}

#dark .ex__experiences {
  color: #f5f5f5;
}

.ex__head-text {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  text-align: center;

  e &.kurdish {
    font-family: "kurdish";
  }
}

.ex__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Defines 3 columns */
  gap: 1rem;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Two columns for medium-sized screens */
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Single column for smaller screens */
  }
}

.ex__card {
  padding: 0.75rem;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &.kurdish {
    flex-direction: row-reverse; // Flip the content for Kurdish language
  }
}

.ex__item {
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 0.75rem;

  &.kurdish {
    text-align: right; // Align text to the right for Kurdish language
  }
}

.ex__logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #f5f5f5;
  border-radius: 50%;

  &.kurdish {
    order: 1; // Move logo to the right for Kurdish language
  }
}

.ex__logo {
  width: 38px;
  height: 38px;
  object-fit: contain;
}

.ex__details {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  &.kurdish {
    align-items: flex-end; // Align text to the right for Kurdish language
  }
}

.ex__company {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.1rem;

  &.kurdish {
    text-align: right; // Right-align company name for Kurdish language
  }
}

.ex__type {
  font-size: 0.9rem;
  color: #666;

  &.kurdish {
    margin-left: 0;
    margin-right: 0.25rem; // Adjust margin for Kurdish layout
  }
}

.ex__link {
  display: flex;
  align-items: center;
  color: #333;
  text-decoration: none;

  &.kurdish {
    justify-content: flex-end; // Align link to the right for Kurdish language
  }
}

.ex__responsibilities {
  font-size: 1rem;
  color: #555;

  &.kurdish {
    text-align: right; // Right-align responsibilities for Kurdish language
  }
}

.ex__description {
  font-size: 0.7rem;
  color: #444;

  &.kurdish {
    text-align: right; // Right-align description for Kurdish
  }
}

.ex__year {
  font-size: 0.8rem;
  color: #888;

  &.kurdish {
    text-align: right; // Right-align year for Kurdish language
  }
}

.ex__controls {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;

  &.kurdish {
    justify-content: flex-end; // Align controls to the right for Kurdish language
  }
}

.ex__show-more,
.ex__show-less {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: white;
  background-color: #4caf50; /* You can customize this color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #388e3c; /* Darker shade on hover */
  }

  &.kurdish {
    font-family: "kurdish3"; // Apply Kurdish font for the button text
  }
}

#dark .ex__show-more,
#dark .ex__show-less {
  background-color: var(--secondary-color);

  &:hover {
    background-color: (var(--secondary-color), 10%);
  }
}

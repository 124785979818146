.language-switcher {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.switcher-container {
  width: 4rem; /* Increased by 0.2rem */
  height: 2.2rem; /* Increased by 0.2rem */
  border-radius: 2.2rem; /* Increased by 0.2rem */
  background-color: var(--light-gray-color);
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
  transition: background-color 0.3s ease;

  &.english {
    background-color: var(--secondary-color);
  }

  &.kurdish {
    background-color: green;
  }
}

.toggle-switch {
  width: 1.7rem; /* Increased by 0.2rem */
  height: 1.7rem; /* Increased by 0.2rem */
  border-radius: 50%;
  background-color: var(--white-color);
  position: absolute;
  top: 50%;
  left: 0.2rem;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &.toggled {
    left: calc(100% - 1.9rem); /* Adjusted to accommodate the size increase */
  }

  .flag-icon {
    width: 1.5rem; /* Slightly increased the size of the flag icon */
  }
}

.app__footer {
  flex: 1;
  width: 100%;
  flex-direction: column;
  margin-top: 1rem;
  margin: auto;
}

.app__footer-cards {
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 1rem;

  .app__footer-card {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 1rem;
    border-radius: 12px;
    transition: all ease-in-out 0.5s;

    .app__footer-icon {
      font-size: 1rem;
      color: var(--secondary-color);
      margin-right: 0.7rem;
    }

    a {
      text-decoration: none;
      font-weight: 600;
      color: #2c2c2c;
      transition: color 0.3s ease;
      #dark & {
        color: white;
        &:hover {
          color: var(--secondary-color);
        }
      }
      &:hover {
        color: var(--secondary-color);
      }
    }

    &:hover {
      transform: scale(1.05);
    }

    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 760px) {
    width: 100%;
  }
}

.app__footer-form {
  width: 60%;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
  height: max-content;
  flex-direction: column;
  margin: 1rem 2rem;
  border-radius: 20px;

  div {
    width: 50%;
    margin: 0.7rem 0.5rem;
    border-radius: 10px;
    cursor: pointer;

    transition: ease-in-out 0.4s all;

    @media screen and (max-width: 1000px) {
      width: 100%;
    }

    input,
    textarea {
      width: 90%;
      background: none;
      padding: 0rem 1rem;
      border: none;
      border-bottom: 2px solid #1a7800;

      outline: none;
      font-size: 1rem;
      padding: 1rem;
      transition: all ease 0.3s;

      &:focus {
        border-color: var(--secondary-color);
        box-shadow: 0px 0px 5px var(--secondary-color);
      }
      #dark & {
        color: white;
      }
    }

    &:hover {
      transform: scale(1.02);
    }
  }

  button {
    border: 2px solid var(--secondary-color);
    border-radius: 15px;
    padding: 0.3rem 2rem;
    font-size: 1rem;
    font-weight: 500;
    color: var(--secondary-color);
    transition: ease-in-out 0.7s all;

    &:hover {
      background-color: var(--secondary-color);
      color: #ffffff;
    }
  }
}

.app__footer-socials {
  display: flex;
  justify-content: center;
  margin: 2rem 0;

  a {
    margin: 0 10px;
    font-size: 2rem;

    padding: 0.5rem;
    border-radius: 50%;
    color: #000;
    transition: transform 0.3s, color 0.3s;

    &:hover {
      transform: scale(1.2);
      color: var(--secondary-color);
    }
  }
}
#dark .app__footer-socials a {
  color: white;
  &:hover {
    transform: scale(1.2);
    color: var(--secondary-color);
  }
}
.app__footer-rights {
  text-align: center;

  font-size: 0.9rem;
  color: #555;
}
